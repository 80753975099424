import { createSSRApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "@/utils/request-interceptor";
import "ant-design-vue/lib/message/style/index.css"
import "virtual:svg-icons-register";
import "virtual:windi.css";
import "vant/es/dialog/style";
import "vant/es/search/style";
import "vant/es/switch/style";
import "vant/es/tabs/style";
import "vant/es/tab/style";
import 'vant/es/image-preview/style';

const pinia = createPinia();

export function createApp() {
  const app = createSSRApp(App);
  // app.use(VueViewer)
  app.use(pinia);
  return {
    app,
  };
}
