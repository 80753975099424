import { fetchUserInfo, getVUserMeetingTypes, getAuthToken } from "@/api";
import { MEETING_STATUS } from "@/constant/meeting";
import { BaseUser, RolePermission } from "@/generated";
import { getLocationQuery } from "@/utils/common";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useLocalStorage, useStorage } from "@vueuse/core";
import { useRequest } from "vue-request";
import { cloneDeep, uniqBy } from "lodash";
import { useCnfSettings } from "@/hooks/POA/useCnfSettings";
import { fetchCreateOptionsOfPOA } from "@/api/POA";
import { s3Service, setS3Config } from "@/utils/aws";
import { mapKeys } from "lodash";

export const useUserStore = defineStore("user", () => {
  const userIdQuery = window.localStorage.getItem("userId");

  // const userId = ref<string | null>('504263');
  const userId = ref<string | null>();
  const speakerType = ref<string | null>();
  const MeetingTypeSelectList = ref([]);
  const userRoles = ref({});

  if (userIdQuery) {
    userId.value = userIdQuery;
    fetchCreateOptionsOfPOA(userIdQuery).then((res) => {
      let mTarr: any = res.map((item: any) => {
        return {
          label: item.add_option_name,
          value: item.add_option_name,
          main_id: item.add_option_id,
          ...item,
        };
      });
      MeetingTypeSelectList.value = uniqBy(mTarr, "label");
      userRoles.value = res[0];
    });
    let awsTxt = window.localStorage.getItem("awsConfig");
    if (!awsTxt) {
      getAuthToken().then((res) => {
        if (res) {
          let awsConfig = {
            albumBucketName: res.bucketName,
            region: res.region,
            accessKeyId: res.ak,
            secretAccessKey: res.sk,
          };
          setS3Config(awsConfig);
          s3Service.init()
          window.localStorage.setItem("awsConfig", JSON.stringify(awsConfig));
        }
      });
    }
  }

  const userinfo = ref<BaseUser>();

  watch(
    userId,
    async (value) => {
      if (value) {
        let locUserinfo = window.localStorage.getItem("userinfo");
        if (locUserinfo) {
          userinfo.value = JSON.parse(locUserinfo);
        } else {
          userinfo.value = await fetchUserInfo({ user_id: value });
          /**
           * 1、登录获取cnf_user_role，无数据的不可登录
            2、cnf_user_role的角色是销售的，还要判断有没有岗位，没有岗位不可登录。如果不是销售，没有这一步判断
            3、分析这一块，如果登录的人不是销售，territory_id传“all”
           */
          if (userinfo.value?.user__cnf_user_roles?.length == 0) {
            window.location.href =
              window.location.origin + `/pages/error/perssion`;
          } else {
            let uRole = userinfo.value?.user__cnf_user_roles[0];
            if (uRole.role_id == "MR" && !uRole.data_scope) {
              window.location.href =
                window.location.origin + `/pages/error/perssion`;
              return;
            }
          }
          useLocalStorage("userinfo", JSON.stringify(userinfo.value));
        }
      }
    },
    { immediate: true },
  );

  const userRole = computed(() => userinfo.value?.user__cnf_user_roles?.[0]);

  const userRolePermissions = computed(
    () => userRole.value?.role.role__cnf_role_permissions,
  );

  console.log(userRolePermissions, "userRolePermissions");
  const userTerritoryIds = computed(
    () =>
      territory.value?.superior_territory_ids
        ?.split("|")
        .filter((item) => !!item),
  );

  // const { data: userSuperiorPersons } = useRequest(
  //   async () => {
  //     console.info('-----------------------')
  //     if (!userinfo.value?.user__base_territories?.length) return;
  //     return fetchStaffTerritoryList({
  //       or: userTerritoryIds.value?.map((item) => ({
  //         territory_id: item,
  //       })),
  //     });
  //   },
  //   { refreshDeps: [userinfo] }
  // );

  // 默认负责人选项列表
  // const defaultResponsePersons = computed(() => [
  // ...(userSuperiorPersons.value ?? []),
  // ]);

  // 默认负责人选项
  // const { data: defaultResponsePersons } = useRequest(
  //   async () => {
  //     if (!userSuperiorPersons.value) return;

  //     const personIds = [
  //       userinfo.value?.user_id,
  //       ...userSuperiorPersons.value.map((item) => item.user_id),
  //     ];
  //     return fetchBaseMarketers({
  //       or: personIds?.map((id) => ({
  //         staff_id: id,
  //       })),
  //     });
  //   },
  //   { refreshDeps: [userSuperiorPersons, userinfo] }
  // );

  // const userinfo = ref<{ id: string; name: string }>({
  //   id: "c77d7ce1-bb2e-45ff-9891-4cf7734b631a",
  //   name: "谭之余",
  // });

  const territory = computed(() => {
    const baseTerritory = userinfo.value?.user__base_territories?.[0];
    const viewTerritory =
      userinfo.value?.user__cnf_user_view_territories?.[0]?.territory;
    if (!baseTerritory && !!viewTerritory) {
      return viewTerritory;
    }
    return baseTerritory;
  });

  const territoryCommonParams = computed(
    () =>
      territory.value && {
        territory_name: territory.value?.territory_name,
        territory_type: territory.value?.territory_type,
        territory_id: territory.value?.territory_id,
      },
  );

  const userCommonParams = computed(() => {
    return {
      user_id: userinfo?.value?.user_id,
      user_name: userinfo?.value?.user_name,
      role_id: userRoleId.value,
    };
  });

  const recordUserParams = computed(() => {
    const params = {
      ...territoryCommonParams.value,
      ...userCommonParams.value,
    };
    return mapKeys(params, (value, key) => "record_" + key);
  });

  const userRoleId = computed(() => {
    let userRoleIdTxt = "";
    if (userinfo.value?.user__cnf_user_roles?.length > 0) {
      let uRole = userinfo.value?.user__cnf_user_roles[0];
      if (uRole.role_id?.includes("MR")) {
        // 非销售判断
        userRoleIdTxt = "MR";
      } else if (uRole.role_id?.includes("DM")) {
        userRoleIdTxt = "DM";
      } else if (uRole.role_id?.includes("AM")) {
        userRoleIdTxt = "AM";
      } else if (uRole.role_id?.includes("RM")) {
        userRoleIdTxt = "RM";
      } else {
        userRoleIdTxt = uRole.role_id;
      }
    }
    if (territory.value) {
      // 销售
      userRoleIdTxt = territory.value.territory_type;
    }
    return userRoleIdTxt;
  });
  const settingSpeakerTypeFn = (type: any) => {
    speakerType.value = type;
  };

  const { withinPOAPeriod } = useCnfSettings();

  const resultMeetingSelectList = computed(() => {
    const result = cloneDeep(MeetingTypeSelectList.value);

    const poaItem = result.find((item) => item.add_option_name === "POA计划");
    if (poaItem) {
      poaItem.disabled = !withinPOAPeriod.value;
      poaItem.url = "/pages/POA/create";
    }
    return result;
  });

  const checkPermission = (
    permission: RolePermission,
    permissionType: "visible" | "operational" | "exportable" = "visible",
  ) => {
    if (!userRole?.value) return true;
    const hasPermission = userRolePermissions.value?.some(
      (item) => item.module === permission && item[permissionType] === 1,
    );
    return hasPermission;
  };

  return {
    userinfo,
    userId,
    userRole,
    userRolePermissions,
    checkPermission,
    // userSuperiorPersons,
    // defaultResponsePersons,
    territory,
    territoryCommonParams,
    userCommonParams,
    userTerritoryIds,
    recordUserParams,
    userRoles,
    userRoleId,
    speakerType,
    settingSpeakerTypeFn,
    MeetingTypeSelectList: resultMeetingSelectList,
  };
});
