import {
  fetchConfigOfCreatePOA,
  fetchPOAMeetingTypes,
  fetchCreateOptionsOfPOA,
  getUserTerritoryRelated,
} from "@/api/POA";
import { useUserStore } from "@/store/user";
import { tryParseJSON } from "@/utils/common";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRequest } from "vue-request";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

/** 关于创建 POA 相关配置信息 */
export const useCnfSettings = () => {
  const { data } = useRequest(() => fetchConfigOfCreatePOA());

  const { userId, territory } = storeToRefs(useUserStore());

  const { data: territoryOptions } = useRequest(
    () =>
      getUserTerritoryRelated({
        territory_id: territory?.value?.territory_id,
        territory_type: "MR",
        need_vacancy: true,
        only_vacancy: territory?.value?.territory_type !== "MR",
      }),
    {
      formatResult: (data) =>
        data?.map((item) => ({
          extra: [
            {
              field: "territory_type",
              value: item.territory_type,
            },
            {
              field: "territory_name",
              value: item.territory_id,
            },
            {
              field: "user_id",
              value: item.user_id,
            },
            {
              field: "user_name",
              value: item.user_name,
            },
          ],
          text: item.territory_id,
          label: item.territory_id + " " + item.user_name,
          value: item.territory_id,
        })),
      ready: computed(() => !!territory?.value),
    },
  );

  // 会议类型
  const { data: meetingTypes } = useRequest(
    () => fetchPOAMeetingTypes(userId?.value),
    {
      formatResult: (data) => {
        return data?.map((item) => ({
          text: item.meeting_type,
          label: item.meeting_type,
          value: item.meeting_type,
          kvp_key_id: item.meeting_type_kvp,
        }));
      },
      ready: computed(() => !!userId?.value),
    },
  );

  // 可选月份
  const availableMonth = computed(
    () =>
      tryParseJSON(
        data.value?.find?.((item) => item?.setting_id === "campaign_months")
          ?.setting_value,
      )?.map?.((item) => ({
        text: item,
        label: item,
        value: item,
        kvp_key_id: "marketing_project_001",
      })) || [],
  );

  // 活动计划当前季度
  const currentQuater = computed(
    () =>
      data.value?.find?.((item) => item?.setting_id === "campaign_quarter")
        ?.setting_value,
  );

  const withinPOAPeriod = computed(() => {
    const startTime = data.value?.find?.(
      (item) => item.setting_id === "campaign_start_date",
    );
    const endTime = data.value?.find?.(
      (item) => item.setting_id === "campaign_end_date",
    );

    const inTimeRange = dayjs()?.isBetween?.(
      dayjs(startTime?.setting_value).startOf("day"),
      dayjs(endTime?.setting_value).endOf("day"),
      null,
      "[]",
    );
    console.log(inTimeRange, "inTimeRange");
    // const inTimeRange = true
    return inTimeRange;
  });

  // 可选月份
  const availableMonthAndAll = computed(() => {
    const arr = tryParseJSON(
      data.value?.find?.((item) => item?.setting_id === "campaign_months")
        ?.setting_value,
    )?.map?.((item) => ({
      text: item,
      label: item,
      value: item,
      kvp_key_id: "marketing_project_001",
    }));
    return (
      arr?.concat([
        {
          text: "全部",
          label: "全部",
          value: "",
          kvp_key_id: "",
        },
      ]) || []
    );
  });

  // 会议类型
  const { data: meetingTypesAndAll } = useRequest(
    () => fetchPOAMeetingTypes(userId?.value),
    {
      formatResult: (data) => {
        return data
          ?.map((item) => ({
            text: item.meeting_type,
            label: item.meeting_type,
            value: item.meeting_type,
            kvp_key_id: item.meeting_type_kvp,
          }))
          .concat([
            {
              text: "全部",
              label: "全部",
              value: "",
              kvp_key_id: "",
            },
          ]);
      },
      ready: computed(() => !!userId?.value),
    },
  );
  return {
    withinPOAPeriod,
    availableMonth,
    meetingTypes,
    currentQuater,
    territoryOptions,
    availableMonthAndAll,
    meetingTypesAndAll,
  };
};
