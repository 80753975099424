import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
// import * as config from "./config/index";
// 优化从表里动态获取
let awsTxt = window.localStorage.getItem("awsConfig");
let awsConfig = {};
if (awsTxt) {
  awsConfig = JSON.parse(awsTxt);
}

// const awsConfig = config["dev"];
// const awsConfig = config[process.env.NODE_ENV === 'production' ? 'production' : 'dev'];

let albumBucketName: string;
let region: string;
let accessKeyId: string;
let secretAccessKey: string;

export function setS3Config(awsConfig: any) {
  albumBucketName = awsConfig["albumBucketName"];
  region = awsConfig["region"];
  accessKeyId = awsConfig["accessKeyId"];
  secretAccessKey = awsConfig["secretAccessKey"];
}

setS3Config(awsConfig);

export function parseFileString(file) {
  const dotIndex = file.lastIndexOf(".");
  return {
    name: file.slice(0, dotIndex),
    suffix: file.slice(dotIndex),
  };
}

// s3 文件保存根目录
export const s3DirRoot = "";
// 访问地址
// https://tkio-test-cnbej-92867-dabhcpmicroseg-001.s3.cn-north-1.amazonaws.com.cn/images/
class S3Service {
  constructor(private rootPath: string = "") {
    this.init();
  }

  init() {
    this.s3 = new S3Client({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
      },
    });
  }

  get baseUrl() {
    return `https://${albumBucketName}.s3.${region}.amazonaws.com.cn`;
  }
  /** 获取访问地址 */
  async getFileUrl(path: string, { withRoot = true } = {}) {
    console.log("get file path: ", path);
    // if (!path.startsWith("/")) {
    //   path = "/" + path;
    // }
    // if (!path.endsWith("/")) {
    //   path = path + "/";
    // }

    const Key = path;
    const command = new GetObjectCommand({
      Bucket: albumBucketName,
      Key,
    });
    console.log(this.s3, "this.s3");
    // Create the presigned URL.
    const signedUrl = await getSignedUrl(this.s3, command, {
      expiresIn: 3600,
    });
    return signedUrl;
  }
  /** 创建目录 */
  async createDir(dirName: string, dirPath = "") {
    let albumKey = encodeURIComponent(this.rootPath + dirPath);
    if (albumKey) {
      albumKey += "/";
    }
    dirName = dirName.trim();
    try {
      const key = albumKey + dirName + "/";
      const params = {
        Bucket: albumBucketName,
        Key: key,
      };
      console.info(params, "paramsparamsparamsparams");
      await this.s3.send(new PutObjectCommand(params));
      console.log("Successfully created album.");
    } catch (err) {
      console.error(err);
    }
  }
  /** 上传文件 */
  async upload(
    file: File,
    { path = "/", filename = undefined as string | undefined } = {}
  ) {
    // if (!path.startsWith("/")) {
    //   path = "/" + path;
    // }
    if (!path.endsWith("/")) {
      path = path + "/";
    }
    // console.info(file, path);
    try {
      const albumKey = this.rootPath + path;
      const fileName = filename
        ? `${filename}${parseFileString(file.name).suffix}`
        : file.name;
      const photoKey = albumKey + fileName;
      // console.log("photoKey: ", photoKey);
      const uploadParams = {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file,
      };
      try {
        // console.log(uploadParams, "uploadParams");
        await this.s3.send(new PutObjectCommand(uploadParams));
        return photoKey;
        console.log("Successfully uploaded photo.");
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
export const s3Service = new S3Service(s3DirRoot);
