import { POACreateDTO } from "@/type/POA";
import { tryParseJSON } from "@/utils/common";
import globalAxios from "axios";
import { pad } from "lodash";
import qs from "qs";

// 获取创建 POA 配置数据
export const fetchConfigOfCreatePOA = async () => {
  const url = "/api/standard/odata/cnfsettings";
  const res = await globalAxios.get(url);
  return res.data;
};

// 获取用户可填写的会议类型清单
export const fetchPOAMeetingTypes = async (user_id: string) => {
  const url = `/api/standard/OData/VUserMeetingTypes?$filter=user_id eq '${user_id}'&$orderby=sequence asc`;
  const res = await globalAxios.get(url);
  return res.data;
};

// 获取用户可新建的内容清单，会议类型+POA计划
export const fetchCreateOptionsOfPOA = async (user_id: string) => {
  const url = `/api/standard/OData/VUserAddOptions?$filter=user_id eq '${user_id}'&$orderby=sequence asc`;
  const res = await globalAxios.get(url);
  return res.data;
};

// 保存POA计划的单个会议
export const savePOA = async (data: any) => {
  const url = "/api/webmeetingapi/Campaign/Save";
  const res = await globalAxios.post(url, data);
  if (res.data?.code !== "Success") {
    throw new Error(res.data?.message || res.data?.title);
  }

  return res.data;
};

// 获取所有POA计划列表，包含展开的下属计划列表（包含其中的每个POA计划能否填写/修改、能否提交、能否撤回、能否下钻）
export const fetchCampaignPortfolios = async ({
  territory_id,
  quarter,
  parent_territory_id,
}: {
  territory_id?: string;
  quarter?: string;
  parent_territory_id?: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetCampaignPortfolios`;
  const res = await globalAxios.get(url, {
    params: { territory_id, quarter, parent_territory_id },
  });
  return res.data;
};

// 获取单个POA计划的具体信息-按人员汇总
export const fetchCampaignsStatByTerritory = async ({
  campaign_portfolio_id,
  is_marketing_project,
}: {
  campaign_portfolio_id: string;
  is_marketing_project: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetCampaignsStatByTerritory`;
  const res = await globalAxios.get(url, {
    params: { campaign_portfolio_id, is_marketing_project },
  });
  return res.data;
};

// 获取单个POA计划的具体信息-按月份汇总
export const fetchCampaignsStatByMonth = async ({
  campaign_portfolio_id,
  is_marketing_project,
}: {
  campaign_portfolio_id: string;
  is_marketing_project: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetCampaignsStatByMonth`;
  const res = await globalAxios.get(url, {
    params: { campaign_portfolio_id, is_marketing_project },
  });
  return res.data;
};

// 获取单个POA计划的具体信息-按会议类型汇总
export const fetchCampaignsStatByMeetingType = async ({
  campaign_portfolio_id,
  is_marketing_project,
}: {
  campaign_portfolio_id: string;
  is_marketing_project: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetCampaignsStatByMeetingType`;
  const res = await globalAxios.get(url, {
    params: { campaign_portfolio_id, is_marketing_project },
  });
  return res.data;
};

// 获取单个POA计划的具体信息-会议清单（包含其中的每个会议能否修改、能否删除）
export const fetchCampaigns = async (params: {
  campaign_portfolio_id: string;
  month: string;
  meeting_type: string;
  search_user: string;
  page_size: number;
  page_index: number;
  is_marketing_project: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetCampaigns`;
  const res = await globalAxios.get(url, { params });
  return res.data;
};

// 获取单个POA计划的具体信息-审批历史
export const fetchRecords = async ({
  campaign_portfolio_id,
}: {
  campaign_portfolio_id: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetRecords`;
  const res = await globalAxios.get(url, { params: { campaign_portfolio_id } });
  return res.data;
};

// 获取能否提交某个POA计划
export const CheckSubmit = async ({
  campaign_portfolio_id,
}: {
  campaign_portfolio_id: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/CheckSubmit`;
  const res = await globalAxios.get(url, { params: { campaign_portfolio_id } });
  return res.data;
};

// 提交POA计划
export const fetchSubmit = async (data: {
  campaign_portfolio_id: string;
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/Submit`;
  const res = await globalAxios.post(url, data);
  return res.data;
};

// 获取能否撤回某个POA计划
export const checkCallback = async ({
  campaign_portfolio_id,
}: {
  campaign_portfolio_id: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/CheckCallback`;
  const res = await globalAxios.get(url, { params: { campaign_portfolio_id } });
  return res.data;
};

// 撤回POA计划
export const callback = async (data: {
  campaign_portfolio_id: string;
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/Callback`;
  const res = await globalAxios.post(url, data);
  return res.data;
};

// 获取我提交的清单，包括已审批的
export const fetchMySubmits = async (params: {
  user_id: string;
  page_size: number;
  page_index: number;
  include_processed: boolean;
  approval_type_kvp?: string;
  search_key?: string;
}) => {
  const url = `/api/webmeetingapi/Approval/GetSubmits`;
  const res = await globalAxios.get(url, { params });
  return res.data;
};

// 审批批准
export const Approve = async (data: {
  approval_record_id: string;
  approval_id: string;
  comments: string;
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/Approval/Approve`;
  const res = await globalAxios.post(url, data);
  return res.data;
};

// 审批拒绝
export const Reject = async (data: {
  approval_record_id: string;
  approval_id: string;
  comments: string;
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/Approval/Reject`;
  const res = await globalAxios.post(url, data);
  return res.data;
};
// 返回审批
export const Return = async (data: {
  approval_record_id: string;
  approval_id: string;
  comments: string;
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/Approval/Return`;
  const res = await globalAxios.post(url, data);
  return res.data;
};
// 获取我审批的清单，包括已审批的
export const fetchMyApprovals = async (params: {
  user_id: string;
  include_processed: boolean;
  page_size: number;
  page_index: number;
  approval_type_kvp?: string;
  search_key?: string;
}) => {
  const url = `/api/webmeetingapi/Approval/GetApprovals`;
  const res = await globalAxios.get(url, { params });
  return res.data;
};

// 获取本人同级、所有上级及所有下属员工
export const getUserTerritoryRelated = async (
  {
    territory_id,
    territory_type,
    need_vacancy,
    only_vacancy,
  }: {
    territory_id: string;
    territory_type?: string;
    need_vacancy?: boolean;
    only_vacancy?: boolean;
  },
  {
    page_index = "0",
    page_size = "10",
  }: { page_size?: string; page_index?: string } = {}
) => {
  let url = `/api/webmeetingapi/api/MyAccount/GetUserTerritoryRelated?territory_id=${territory_id}&need_self=true&need_parent=false&need_sub=true&need_sibling=false&page_size=${page_size}&page_index=${page_index}&only_vacancy=${only_vacancy}`;

  if (territory_type) {
    url += `&territory_type=${territory_type}`;
  }
  if (need_vacancy) {
    url += `&need_vacancy=${need_vacancy}`;
  }
  const res = await globalAxios.get(url);
  return res.data?.data;
};

// 获取 POA 详情
export const fetchPOADetail = (params: {
  campaign_id: string;
  quarter: string;
}) => {
  const url = "/api/webmeetingapi/Campaign/GetCampaign";
  return globalAxios.get(url, { params }).then((res) => {
    const data = res.data.data as POACreateDTO;
    data.products = tryParseJSON(data?.products);
    data.institutions = tryParseJSON(data?.institutions);
    data.speakers = tryParseJSON(data?.speakers);
    return data;
  });
};

// 删除POA单个活动
export const deletePOA = async (data: any) => {
  const url = "/api/webmeetingapi/Campaign/Delete";
  const res = await globalAxios.post(url, data);
  if (res.data?.code !== "Success") {
    throw new Error(res.data?.message || res.data?.title);
  }

  return res.data;
};

// 下载
export const ExportCampaigns = async ({
  campaign_portfolio_id,
}: {
  campaign_portfolio_id: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/ExportCampaigns`;
  const res = await globalAxios.get(url, {
    params: { campaign_portfolio_id },
    responseType: "blob",
  });
  console.log("res--->", res.headers["content-disposition"]);
  const string = res.headers["content-disposition"];
  // 提取最后一个引号中的值
  const startIdx = string.lastIndexOf("'") + 1;
  const encodedValue = string.substring(startIdx);

  // URL解码
  const decodedValue = decodeURIComponent(encodedValue);
  return { data: res.data, name: decodedValue };
};

// 一键审批
export const ApproveAll = async (data: {
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/Approval/ApproveAll`;
  const res = await globalAxios.post(url, data);
  return res.data;
};

// 获取单个POA计划
export const fetchSinglePOA = async ({
  quarter,
  territory_id,
}: {
  quarter: string;
  territory_id: string;
}) => {
  const url = `/api/webmeetingapi/CampaignPortfolio/GetCampaignPortfolio?quarter=${quarter}&territory_id=${territory_id}`;
  const res = await globalAxios.get(url);
  return res.data?.data;
};

// 获取项目名称列表
export function fetchProjectList({
  meeting_category_kvp,
  cost_department_kvp,
  product_ids = [],
}: {
  meeting_category_kvp: string;
  cost_department_kvp: string;
  product_ids: string[];
}) {
  let url = `/api/standard/odata/baseProjects?$orderby=sequence&$filter=meeting_category_kvp eq '${meeting_category_kvp}' and cost_department_kvp eq '${cost_department_kvp}'`;

  if (product_ids.length) {
    url += ` and (${product_ids
      .map((item) => `contains(product_ids,'${item}')`)
      .join(" or ")})`;
  }

  return globalAxios.get(url).then((res) => res.data);
}

// 公用召回
export const publicCallback = async (data: {
  approval_record_id: string;
  approval_id: string;
  territory_id: string;
  territory_name: string;
  territory_type: string;
  user_id: string;
  user_name: string;
}) => {
  const url = `/api/webmeetingapi/Approval/Callback`;
  const res = await globalAxios.post(url, data);
  return res.data;
};

// 获取 POA 项目列表
export const fetchPOAProjectList = (
  data: {
    meeting_type_kvp?: string;
    product_ids?: [] | string;
    territory_id?: string;
    user_id?: string;
    meeting_category_kvp?: string;
    cost_department_kvp?: string;
  } = {},
  type: "POA" | "normal" = "normal"
) => {
  if (Array.isArray(data.product_ids)) {
    data.product_ids = data.product_ids.join(",");
  }
  const url =
    `/api/webmeetingapi/Project/${
      type === "POA"
        ? "GetSelectableProjects_POA"
        : "GetSelectableProjects_Meeting"
    }` + qs.stringify(data, { addQueryPrefix: true });
  return globalAxios.get(url).then((res) => res.data.data);
};

// 获取 POA 项目对应的会议类型
export const fetchPOAMeetingType = (project_id: string) => {
  const url = `/api/webmeetingapi/Campaign/GetMeetingType?project_id=${project_id}`;
  return globalAxios.get(url).then((res) => res.data.data);
};

// 获取指定月份的周列表
export const fetchMondaysByMonth = async (month: string) => {
  const url = `/api/webmeetingapi/Generic/GetMondays?month=${month}`;
  const res = await globalAxios.get(url);
  return res.data;
};
