<script setup lang="ts">
import { onLaunch, onShow, onHide } from "@dcloudio/uni-app";
import { storeToRefs } from "pinia";
import { useUserStore } from "./store/user";
import { useViewMode } from "./hooks/useViewMode";
import { watch } from "vue";
import { getLocationQuery } from "./utils/common";

// useUserStore();
const checkOrientation = () => {
  if (window.orientation === 90 || window.orientation === -90) {
    alert("为了您的良好体验,请在手机/平板竖屏模式下操作");
  }
  if (import.meta.env.VITE_VIEW_MODW == "mobile") {
    // const ua = navigator.userAgent.toLowerCase();
    // uni.getSystemInfoSync().windowWidth // 获取屏幕尺寸
    // 移动模式下 样式一直为移动样式
    setTimeout(() => {
      document.querySelector("uni-layout").className = "";
    }, 500);
  }
};
window.addEventListener("orientationchange", checkOrientation, false);
checkOrientation();
const onpopstate = () => {
  // 处理浏览器返回操作
  // console.info('浏览返回操作')
  uni.hideLoading();
};
window.addEventListener("popstate", onpopstate, false);
onLaunch(() => {
  console.log("App Launch");
  // 锁定横屏
  // plus.screen.lockOrientation("landscape-primary");
  // 锁定竖屏
  // plus.screen.lockOrientation("portrait-primary");
  if (import.meta.env.VITE_VIEW_MODW == "pc") {
    setTimeout(() => {
      document.getElementById("app").className = "uni-app--showtopwindow";

      // pc 端根路径访问跳转微信扫码登录页面
      const isRootPath = location.pathname === "/" || location.pathname === "";
      const isDev = location.hostname === "localhost";
      if (isRootPath && !isDev) {
        uni.reLaunch({
          url: "/pages/loginScan/index",
        });
      }
      // document.querySelector('uni-layout').className = "uni-app--showtopwindow";
    }, 500);
  }
});
onShow(() => {
  console.log("App Show");
});
onHide(() => {
  console.log("App Hide");
});
</script>
<style lang="less">
@import "./App.less";
@import "./topWindow.less";
</style>
