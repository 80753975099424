<template>
  <div>
    <slot v-if="isPc"></slot>
  </div>
</template>

<script setup lang="ts">
import { MOBILE_PC_WIDTH_DIVIDER } from "@/constant/size";
import { useViewMode } from "@/hooks/useViewMode";

const { isPc } = useViewMode();
</script>

<style lang="scss" scoped></style>
